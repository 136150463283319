<template>
    <v-footer padless>
      <v-card tile class="d-flex flex-column text-center" color="primary">
        <v-card-text class="d-flex flex-column justify-space-between">
          <div class="w-100 content mx-auto d-flex flex-column justify-center flex-wrap">
            <h3>{{ $t('Contact Us') }}</h3>
            <v-divider></v-divider>
            <a href='mailto:filosofiaepoesia@outlook.com.br'><v-icon class="mr-2">mdi-mail</v-icon>filosofiaepoesia@outlook.com.br</a>
            <a href='https://api.whatsapp.com/send?phone=+5511948153588&text=Olá!'><v-icon class="mr-1">mdi-whatsapp</v-icon><v-icon class="mr-2">mdi-phone</v-icon>(11) 94815-3588</a>
          </div>
          <div class="w-100 content mx-auto d-flex flex-column justify-center flex-wrap">
            <h3>{{ $t('Development') }}</h3>
            <v-divider></v-divider>
            <span>Aquarius Tecnologia</span>
            <span>CNPJ: 32.728.059/0001-97</span>
            <a href='mailto:aquariustecnologia@outlook.com'><v-icon class="mr-2">mdi-mail</v-icon>aquariustecnologia@outlook.com</a>
            <a href='https://api.whatsapp.com/send?phone=+5511948153588&text=Olá!' target='_blank'><v-icon class="mr-1">mdi-whatsapp</v-icon><v-icon class="mr-2">mdi-phone</v-icon>(11) 94815-3588</a>
          </div>
          <div class="brand w-100 content mx-auto d-flex flex-column justify-center flex-wrap">
            <span><span class="title">Filosofia e Poesia </span>© 2022</span>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
</template>
<script>
export default {
  name: 'Footer',
  components: {},
};
</script>
<style lang="scss" scoped>
  .v-footer {
    margin-top: 25px;
    text-shadow: 1px 1px 1px black;
    
    .v-card {
      width: 100%;
      height: 375px;

      .v-card__text {
        max-width: 375px;
        height: 325px;
        margin: auto;
        color: white; 
        
        .v-divider {
          width: 175px;
          margin: 3px auto 10px;
          border-width: thin 0 2px 0;
          border-color: orange;
          box-shadow: 0px 0px 4px black;
        }
        
        a { 
          color: white; 
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .v-icon { 
          margin-top: -3px;
          font-size: 20px; 
        }
        
        .brand {  
          margin-bottom: -25px;
          
          span {
            &.title {
              font-size: 20px;
              font-family: 'Pacifico', cursive !important; 
            }
          }          
        }
      }
    }
  }
</style>
